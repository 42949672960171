/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
// import Cookies from 'universal-cookie';
import { put, call } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import ClienteActions from '../ducks/cliente';
import api from '../../services/api';

export function* sendPush({ data }) {
  try {
    yield call(api.post, '/push', data);

    NotificationManager.success(
      'Notificação enviada com sucesso',
      'Envio de Push Notification',
    );

    return yield put(ClienteActions.sendPushSuccess());
  } catch (error) {
    console.log('error', error);
    NotificationManager.error(
      'Ocorreu um erro, tente novamente mais tarde',
      'Envio de Push Notification',
    );
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* getDestaques() {
  try {
    const { data: response } = yield call(api.get, '/novidade');

    return yield put(ClienteActions.getDestaquesSuccess(response));
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* updateDestaques({ data, id }) {
  try {
    yield call(api.put, `/novidade/${id}`, data);

    NotificationManager.success(
      'Destaque atualizada com sucesso',
      'Destaques',
    );

    yield put(ClienteActions.updateDestaquesSuccess());
    return yield call(getDestaques);
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* deleteDestaques({ id }) {
  try {
    yield call(api.delete, `/novidade/${id}`);

    NotificationManager.success(
      'Destaque deletada com sucesso',
      'Destaques',
    );

    yield put(ClienteActions.deleteDestaquesSuccess());
    return yield call(getDestaques);
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* createDestaques({ url, data }) {
  try {
    const { data: response } = yield call(api.post, '/novidade', url);

    yield call(api.post, `/file/${response.novidadeId}`, data);

    NotificationManager.success(
      'Destaque criada com sucesso',
      'Destaques',
    );

    yield put(ClienteActions.createDestaquesSuccess());
    return yield call(getDestaques);
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* getChamados() {
  try {
    const { data: response } = yield call(api.get, '/chamado');

    const formatResponse = response.length === 0 ? null : response;

    return yield put(ClienteActions.getChamadosSuccess(formatResponse));
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* updateChamados({ data, id }) {
  try {
    yield call(api.put, `/chamado/${id}`, data);

    yield put(ClienteActions.getChamadosRequest());

    NotificationManager.success(
      'Chamado atualizado com sucesso',
      'Chamado',
    );

    return yield put(ClienteActions.updateChamadosSuccess());
  } catch (error) {
    NotificationManager.error(
      'Serviço indisponivel no momento, tente novamente mais tarde',
      'Chamado',
    );
    return yield put(ClienteActions.loadingCancel());
  }
}
