/* eslint-disable default-param-last */
/* eslint-disable no-unused-vars */
import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  sendPushRequest: ['data'],
  sendPushSuccess: null,
  getDestaquesRequest: null,
  getDestaquesSuccess: ['data'],
  updateDestaquesRequest: ['data', 'id'],
  updateDestaquesSuccess: null,
  deleteDestaquesRequest: ['id'],
  deleteDestaquesSuccess: null,
  createDestaquesRequest: ['url', 'data'],
  createDestaquesSuccess: null,
  getChamadosRequest: null,
  getChamadosSuccess: ['data'],
  updateChamadosRequest: ['data', 'id'],
  updateChamadosSuccess: null,
  loadingCancel: null,
});

export const ClienteTypes = Types;
export default Creators;

export const INITAL_STATE = {
  novidades: null,
  chamados: null,
  loading: false,
};

export const reducer = createReducer(INITAL_STATE, {
  [Types.SEND_PUSH_REQUEST]: (state = INITAL_STATE) => ({
    loading: true,
  }),
  [Types.SEND_PUSH_SUCCESS]: (state = INITAL_STATE) => ({
    loading: false,
  }),
  [Types.GET_DESTAQUES_REQUEST]: (state = INITAL_STATE) => ({
    loading: true,
  }),
  [Types.GET_DESTAQUES_SUCCESS]: (state = INITAL_STATE, action) => ({
    novidades: action.data,
    loading: false,
  }),
  [Types.UPDATE_DESTAQUES_REQUEST]: (state = INITAL_STATE) => ({
    loading: true,
  }),
  [Types.UPDATE_DESTAQUES_SUCCESS]: (state = INITAL_STATE) => ({
    loading: false,
  }),
  [Types.DELETE_DESTAQUES_REQUEST]: (state = INITAL_STATE) => ({
    loading: true,
  }),
  [Types.DELETE_DESTAQUES_SUCCESS]: (state = INITAL_STATE) => ({
    loading: false,
  }),
  [Types.CREATE_DESTAQUES_REQUEST]: (state = INITAL_STATE) => ({
    loading: true,
  }),
  [Types.CREATE_DESTAQUES_SUCCESS]: (state = INITAL_STATE) => ({
    loading: false,
  }),
  [Types.GET_CHAMADOS_REQUEST]: (state = INITAL_STATE) => ({
    loading: true,
  }),
  [Types.GET_CHAMADOS_SUCCESS]: (state = INITAL_STATE, action) => ({
    chamados: action.data,
    loading: false,
  }),
  [Types.UPDATE_CHAMADOS_REQUEST]: (state = INITAL_STATE) => ({
    loading: true,
  }),
  [Types.UPDATE_CHAMADOS_SUCCESS]: (state = INITAL_STATE) => ({
    loading: false,
  }),
  [Types.LOADING_CANCEL]: (state = INITAL_STATE) => ({
    loading: false,
  }),
});
