/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { MdPassword } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NavigationAction from '../../store/ducks/navigation';
import UserAction from '../../store/ducks/user';

import ButtonComponent from '../../components/Button';
import TextInput from '../../components/TextInput';

// import { Container } from './styles';

export default function Login() {
  const dispatch = useDispatch();
  const navigation = useHistory();
  const [userData, setUserData] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    dispatch(NavigationAction.setNavigation(navigation));
  }, []);

  function handleUserLogin() {
    return dispatch(UserAction.userLoginRequest(userData));
  }

  return (
    <div className="flex flex-col justify-center items-center w-screen h-screen">
      <div className="flex flex-col justify-center rounded items-center bg-secondary w-[95%] md:w-3/6 h-4/6 p-8">
        <h3 className="text-textSecondary text-2xl font-semibold">BasePro</h3>
        <h3 className="text-textSecondary text-xl font-thin mb-4">Gerenciador de Aplicativos</h3>
        <div className="w-[95%] md:w-4/6">

          <TextInput
            placeholder="Usuario"
            value={userData.username}
            onChange={({ target }) => setUserData({
              ...userData,
              username: target.value,
            })}
            icon={() => (
              <AiOutlineUser
                color="#171819"
                size={30}
              />
            )}
          />
          <TextInput
            placeholder="Senha"
            type="password"
            value={userData.password}
            onChange={({ target }) => setUserData({
              ...userData,
              password: target.value,
            })}
            icon={() => (
              <MdPassword
                color="#171819"
                size={30}
              />
            )}
          />
          <ButtonComponent border text="Entrar" onClick={() => handleUserLogin()} />
        </div>
      </div>
    </div>
  );
}
