import { combineReducers } from 'redux';

import { reducer as navigation } from './navigation';
import { reducer as user } from './user';
import { reducer as cliente } from './cliente';

const reducers = combineReducers({
  user,
  navigation,
  cliente,
});

export default reducers;
