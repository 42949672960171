import React from 'react';
import { AiFillAppstore } from 'react-icons/ai';
import { RiFileList3Fill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
// import { Container } from './styles';

export default function Chamados() {
  const navigation = useHistory();
  return (
    <div className="flex w-full h-[90vh] flex-col md:flex-row items-center justify-center">
      <button onClick={() => navigation.push('/app/aplicativo')} className="flex flex-row items-center justify-center bg-buttonPrimary cursor-pointer rounded p-20" type="button">
        <AiFillAppstore color="#fff" size={25} />
        <h3 className="text-white font-bold text-lg ml-2">Aplicativo</h3>
      </button>
      <button onClick={() => navigation.push('/app/chamados')} className="flex flex-row items-center justify-center bg-buttonPrimary cursor-pointer rounded p-20 md:ml-10" type="button">
        <RiFileList3Fill color="#fff" size={25} />
        <h3 className="text-white font-bold text-lg ml-2">Chamados</h3>
      </button>
    </div>
  );
}
