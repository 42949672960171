import { all, takeLatest } from 'redux-saga/effects';

import { UserTypes } from '../ducks/user';
import { userLogin } from './user';

import { ClienteTypes } from '../ducks/cliente';
import {
  sendPush,
  getDestaques,
  updateDestaques,
  deleteDestaques,
  createDestaques,
  getChamados,
  updateChamados,
} from './cliente';

export default function* rootSaga() {
  yield all([
    takeLatest(UserTypes.USER_LOGIN_REQUEST, userLogin),
    takeLatest(ClienteTypes.SEND_PUSH_REQUEST, sendPush),
    takeLatest(ClienteTypes.GET_DESTAQUES_REQUEST, getDestaques),
    takeLatest(ClienteTypes.UPDATE_DESTAQUES_REQUEST, updateDestaques),
    takeLatest(ClienteTypes.DELETE_DESTAQUES_REQUEST, deleteDestaques),
    takeLatest(ClienteTypes.CREATE_DESTAQUES_REQUEST, createDestaques),
    takeLatest(ClienteTypes.GET_CHAMADOS_REQUEST, getChamados),
    takeLatest(ClienteTypes.UPDATE_CHAMADOS_REQUEST, updateChamados),
  ]);
}
