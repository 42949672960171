/* eslint-disable no-unused-vars */
import React from 'react';
import MaterialTable from 'material-table';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root-5': {
      backgroundColor: 'blue',
      boxShadow: 'none',
      color: 'blue',
    },
    '& .MuiToolbar-root-38': {
      color: 'red',
    },
    '& .MuiTableCell-body-117': {
      color: '#E8E8E9',
    },
    '& .MuiTableSortLabel-active-128': {
      color: '#fff',
    },
    '& .MuiTableSortLabel-active-128:hover': {
      color: '#fff',
    },
    '& .MuiTableSortLabel-root-127:hover': {
      color: '#fff',
    },
    '& .MuiIconButton-root-98.MuiIconButton-disabled-102': {
      color: '#E8E8E9',
    },
    '& .MuiTypography-caption-163': {
      color: '#E8E8E9',
    },
    '& .MuiTableCell-root-115': {
      border: 'none',
    },
    '& .MuiSelect-icon-195': {
      color: '#E8E8E9',
    },
    '& .MTableToolbar-title-10': {
      color: '#E8E8E9',
    },
  },
}));

export default function DataTable({
  columns, data, title, width = '100%',
}) {
  return (
    <div>

      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        style={{
          boxShadow: 'none',
          backgroundColor: '#fff',
          width,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'Sem clientes',
          },
          toolbar: {
            searchPlaceholder: 'Pesquisar',
            searchTooltip: 'Pesquisar',
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'clientes',
            firstAriaLabel: 'Primeira página',
            firstTooltip: 'Primeira página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Próxima página',
            nextTooltip: 'Próxima página',
            lastAriaLabel: 'Ultima página',
            lastTooltip: 'Ultima página',
          },
        }}
        options={{
          pageSize: 8,
          pageSizeOptions: [10, 20, 30, 50],
          draggable: false,
          thirdSortClick: false,
        }}
      />
    </div>
  );
}
