import React from 'react';

// import { Container } from './styles';

export default function HeaderComponent() {
  return (
    <div className="h-[50px] w-full bg-secondary flex flex-row items-center p-4">
      <div className="flex flex-row">
        <h3 className="text-textSecondary font-semibold text-xl mr-2">BasePro</h3>
        <h3 className="text-textSecondary font-thin text-xl">| Gerenciador de Aplicativo</h3>
      </div>
    </div>
  );
}
