/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
// import Cookies from 'universal-cookie';
import { put, call, select } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import { TOKEN_KEY } from '../../services/auth';
import UserActions from '../ducks/user';
import api from '../../services/api';

export function* userLogin({ data }) {
  try {
    const { data: response } = yield call(api.post, '/user/login', data);
    const navigationStore = yield select((store) => store.navigation.navigation);

    NotificationManager.success(
      'Login realizado com sucesso',
      'Login',
    );

    sessionStorage.setItem(TOKEN_KEY, response.token);

    navigationStore.push('/app/home');

    return yield put(UserActions.userLoginSuccess());
  } catch (error) {
    console.log('error', error);
    if (error.response) {
      if (error.response.status === 401) {
        NotificationManager.error(
          'USUARIO ou SENHA estão incorreto',
          'Login',
        );
      }
    }
    if (error.response) {
      if (error.response.status === 405) {
        NotificationManager.error(
          'USUARIO não cadastrado',
          'Login',
        );
      }
    }
  }
  return yield put(UserActions.loadingCancel());
}
