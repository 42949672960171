/* eslint-disable no-unused-vars */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import HeaderComponent from '../../components/Header';

import Home from '../../pages/Home';
import Aplicativo from '../../pages/Aplicativo';
import Chamados from '../../pages/Chamados';

function Routes() {
  return (
    <div className="w-full flex flex-col h-full">
      <HeaderComponent />
      <div className="h-full w-full flex-1">
        <Switch>
          <Route exact path="/app/home" component={Home} />
          <Route exact path="/app/aplicativo" component={Aplicativo} />
          <Route exact path="/app/chamados" component={Chamados} />
          <Redirect from="/app" to="app/home" />
        </Switch>
      </div>
    </div>
  );
}

export default function App() {
  return <Routes />;
}
