import React from 'react';

import {
  Modal,
  Backdrop,
  makeStyles,
  Container,
} from '@material-ui/core';

import ScrollContainer from '../ScrollComponent';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: '#171819',
    padding: '10px',
  },
});

export default function ModalComponent({
  children,
  openState,
  closeAction,
  height = '90%',
  width = '90%',
  modalStyle,
  containerStyle,
}) {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openState}
      onClose={closeAction}
      style={modalStyle}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Container
        className={classes.container}
        style={{
          height,
          width,
          ...containerStyle,
        }}
      >
        <ScrollContainer>
          {children}
        </ScrollContainer>
      </Container>
    </Modal>
  );
}
