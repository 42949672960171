/* eslint-disable default-param-last */
/* eslint-disable no-unused-vars */
import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userLoginRequest: ['data'],
  userLoginSuccess: null,
  loadingCancel: null,
});

export const UserTypes = Types;
export default Creators;

export const INITAL_STATE = {
  loading: false,
};

export const reducer = createReducer(INITAL_STATE, {
  [Types.USER_LOGIN_REQUEST]: (state = INITAL_STATE) => ({
    loading: true,
  }),
  [Types.USER_LOGIN_SUCCESS]: (state = INITAL_STATE) => ({
    loading: false,
  }),
  [Types.LOADING_CANCEL]: (state = INITAL_STATE) => ({
    loading: false,
  }),
});
